var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login",on:{"click":_vm.clearErr}},[_c('img',{staticClass:"banner",attrs:{"src":require("@/images/loginBanner.png"),"alt":""}}),_c('div',{staticClass:"language"},[_c('span',{style:(_vm.checkIndex == 0 ? 'color:#fff' : ''),on:{"click":function($event){return _vm.switchY(0)}}},[_vm._v("CN")]),_c('div'),_c('span',{style:(_vm.checkIndex == 1 ? 'color:#fff' : ''),on:{"click":function($event){return _vm.switchY(1)}}},[_vm._v("EN")])]),_c('div',{class:this.$store.state.index.languageType == 'zh'
        ? 'loginpageCon'
        : 'loginpageCon loginpageConen'},[_c('div',{staticClass:"systemName"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticClass:"loginLogo",attrs:{"src":require("@/images/loginLogo.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("login.txt2"))+" ")])]),_c('div',{staticClass:"loginBox"},[_c('div',{class:_vm.pageType ? 'Boxtitle' : 'Boxtitle Boxmargin'},[_vm._v(" "+_vm._s(_vm.$t("login.txt10"))+" ")]),_c('div',{staticClass:"inputBox"},[_c('div',{staticClass:"inputModelTxt"},[_vm._v(_vm._s(_vm.$t("login.txt4")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],class:_vm.errTtype[0].type ? 'inputBlack' : 'inputBlack inputBlackErr',attrs:{"type":"text","placeholder":_vm.focusInputplaceholderArr[0]},domProps:{"value":(_vm.name)},on:{"focus":function($event){return _vm.setplaceholderVal(1)},"blur":function($event){return _vm.DefocusplaceholderVal(1)},"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}})]),_c('div',{staticClass:"inputBox"},[_c('div',{staticClass:"inputModelTxt"},[_vm._v(_vm._s(_vm.$t("login.txt5")))]),_c('div',{staticClass:"code"},[((_vm.posHidden ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:_vm.errTtype[1].type
                ? 'inputBlack code'
                : 'inputBlack code inputBlackErr',attrs:{"placeholder":_vm.focusInputplaceholderArr[1],"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"focus":function($event){return _vm.setplaceholderVal(2)},"blur":function($event){return _vm.DefocusplaceholderVal(2)},"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.posHidden ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:_vm.errTtype[1].type
                ? 'inputBlack code'
                : 'inputBlack code inputBlackErr',attrs:{"placeholder":_vm.focusInputplaceholderArr[1],"type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"focus":function($event){return _vm.setplaceholderVal(2)},"blur":function($event){return _vm.DefocusplaceholderVal(2)},"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:_vm.errTtype[1].type
                ? 'inputBlack code'
                : 'inputBlack code inputBlackErr',attrs:{"placeholder":_vm.focusInputplaceholderArr[1],"type":_vm.posHidden ? 'text' : 'password'},domProps:{"value":(_vm.password)},on:{"focus":function($event){return _vm.setplaceholderVal(2)},"blur":function($event){return _vm.DefocusplaceholderVal(2)},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('img',{attrs:{"src":_vm.posHidden
                ? require('@/newImg/hiddenPos@2x.png')
                : require('@/newImg/checkPos@2x.png'),"alt":""},on:{"click":function($event){_vm.posHidden = !_vm.posHidden}}})])]),_c('div',{staticClass:"Submit",on:{"click":function($event){$event.stopPropagation();return _vm.todesign.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("login.txt1")))]),_c('div',{staticClass:"userAgreement"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.userAgreementType),expression:"!userAgreementType"}],staticClass:"CheckBox",on:{"click":function($event){_vm.userAgreementType = true}}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.userAgreementType),expression:"userAgreementType"}],attrs:{"src":require("@/newImg/loginCheck.png"),"alt":""},on:{"click":function($event){_vm.userAgreementType = false}}}),_c('span',[_vm._v(_vm._s(_vm.$t("login.txt8")))]),_c('div',{on:{"click":_vm.ShowUserAgreement}},[_vm._v(_vm._s(_vm.$t("login.txt9")))])])])]),_c('CommonDialog',{attrs:{"dialogVisible":_vm.isShowDialog,"content":_vm.setContent,"confirmContent":_vm.setConfirmContent,"type":1},on:{"cancelDialog":_vm.cancelDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }