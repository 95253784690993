<template>
  <div class="login" @click="clearErr">
    <img src="@/images/loginBanner.png" alt="" class="banner" />
    <div class="language">
      <span :style="checkIndex == 0 ? 'color:#fff' : ''" @click="switchY(0)"
        >CN</span
      >
      <div></div>
      <span :style="checkIndex == 1 ? 'color:#fff' : ''" @click="switchY(1)"
        >EN</span
      >
    </div>
    <div
      :class="
        this.$store.state.index.languageType == 'zh'
          ? 'loginpageCon'
          : 'loginpageCon loginpageConen'
      "
    >
      <div class="systemName">
        <div style="display: flex; align-items: center">
          <img src="@/images/loginLogo.png" alt="" class="loginLogo" />
          {{ $t("login.txt2") }}
        </div>
        <!-- <div class="jixing"><span></span>{{ $t("login.txt3") }}</div> -->
      </div>
      <div class="loginBox">
        <div :class="pageType ? 'Boxtitle' : 'Boxtitle Boxmargin'">
          {{ $t("login.txt10") }}
        </div>
        <div class="inputBox">
          <div class="inputModelTxt">{{ $t("login.txt4") }}</div>
          <input
            type="text"
            v-model="name"
            @focus="setplaceholderVal(1)"
            @blur="DefocusplaceholderVal(1)"
            :class="
              errTtype[0].type ? 'inputBlack' : 'inputBlack inputBlackErr'
            "
            :placeholder="focusInputplaceholderArr[0]"
          />
        </div>
        <div class="inputBox">
          <div class="inputModelTxt">{{ $t("login.txt5") }}</div>
          <div class="code">
            <input
              :type="posHidden ? 'text' : 'password'"
              :class="
                errTtype[1].type
                  ? 'inputBlack code'
                  : 'inputBlack code inputBlackErr'
              "
              @focus="setplaceholderVal(2)"
              @blur="DefocusplaceholderVal(2)"
              :placeholder="focusInputplaceholderArr[1]"
              v-model="password"
            />
            <img
              :src="
                posHidden
                  ? require('@/newImg/hiddenPos@2x.png')
                  : require('@/newImg/checkPos@2x.png')
              "
              @click="posHidden = !posHidden"
              alt=""
            />
          </div>
        </div>
        <div class="Submit" @click.stop="todesign">{{ $t("login.txt1") }}</div>

        <div class="userAgreement">
          <div
            v-show="!userAgreementType"
            class="CheckBox"
            @click="userAgreementType = true"
          ></div>
          <img
            v-show="userAgreementType"
            @click="userAgreementType = false"
            src="@/newImg/loginCheck.png"
            alt=""
          />
          <span>{{ $t("login.txt8") }}</span>
          <div @click="ShowUserAgreement">{{ $t("login.txt9") }}</div>
        </div>
      </div>
    </div>
    <CommonDialog
      :dialogVisible="isShowDialog"
      :content="setContent"
      :confirmContent="setConfirmContent"
      :type="1"
      @cancelDialog="cancelDialog"
    ></CommonDialog>
  </div>
</template>

<script>
import {
  userLogin,
  userRegister,
  getPhoneVCode,
  pictureVerifyCode,
  getUserInfo,
} from "@/api/user/user";
import CommonDialog from "@/components/commonDialog/CommonDialog.vue";
import eventBus from "@/utils/eventBus";
import axios from "axios";
import { setHttp } from "@/axios/baseURL";
export default {
  name: "Login",
  components: {
    CommonDialog,
  },
  data() {
    return {
      pageType: true, //控制页面状态
      name: "", //
      password: "", //验证码
      btnTime: 59, //验证码走时
      btnType: true, //验证码按钮状态
      t: "", //全局定时器
      imgCode: "", //图形验证码
      imgCodeUrl: "",
      focusInputplaceholderArr: [],
      posHidden: false, //显示或隐藏密码
      InvitationCodeNumList: [
        //邀请码参数
        {
          val: "",
          focus: false,
          Disable: false,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
      ],
      imgCodeList: [
        //图形验证码参数
        {
          val: "",
          focus: false,
          Disable: false,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
        {
          val: "",
          focus: false,
          Disable: true,
        },
      ],
      errTtype: [{ type: true }, { type: true }, { type: true }],
      imgCodePuptype: false,
      errTips: false,
      checkIndex: 0,
      userAgreementType: false,
      isShowDialog: false,
      setContent: this.$t("left.txt12"),
      setConfirmContent: this.$t("left.txt13"),
    };
  },
  watch: {
    // name(newVal, oldVal) {
    //   //手机号
    //   this.name = newVal.replace(/[^\d]/g, "");
    //   if (this.name.length > 11) {
    //     this.name = this.name.slice(0, 11);
    //   }
    // },
    // password(newVal, oldVal) {
    //   //验证码
    //   this.password = newVal.replace(/[^\d]/g, "");
    //   if (this.password.length > 4) {
    //     this.password = this.password.slice(0, 4);
    //   }
    // },
    imgCodeList: {
      handler(newVal, oldVal) {
        //图片验证码
        let str = "";
        newVal.forEach((item) => {
          str += item.val;
        });
        if (str.length == 5) {
          this.imgCode = str;
          this.getVerificationCode();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let arr = [this.$t("login.txt6"), this.$t("login.txt7")];
    this.focusInputplaceholderArr = arr;
    this.$store.state.index.languageType == "zh"
      ? (this.checkIndex = 0)
      : (this.checkIndex = 1);
  },
  methods: {
    //警告弹框
    cancelDialog() {
      this.isShowDialog = false;
    },
    // 显示用户协议
    ShowUserAgreement() {
      this.$router.push("/userAgreement");
    },
    switchY(type) {
      this.checkIndex = type;
      localStorage.setItem("languageSet", this.checkIndex == 0 ? "zh" : "en");
      this.$i18n.locale = this.checkIndex == 0 ? "zh" : "en";
      this.$store.commit(
        "index/setlanguageType",
        this.checkIndex == 0 ? "zh" : "en"
      );
      let arr = [this.$t("login.txt6"), this.$t("login.txt7")];
      this.focusInputplaceholderArr = arr;
    },
    //打开图形验证码弹框
    openImgCodePup() {
      if (this.btnType) {
        if (this.name == "") {
          this.$set(this.errTtype, 0, { type: false });
          this.$set(this.focusInputplaceholderArr, 0, this.$t("login.txt6"));
          return;
        }
        // if (!this.checkMobile(this.name)) {
        //   this.$messagePup("您输入的手机号有误");
        //   return;
        // }
        this.getImgcode();
        this.imgCodePuptype = true;
      }
    },

    //关闭图形验证码弹框
    hiddenPup() {
      this.imgCodeList.forEach((item, index) => {
        if (index == 0) {
          this.$set(this.imgCodeList, index, {
            val: "",
            focus: false,
            Disable: false,
          });
        } else {
          this.$set(this.imgCodeList, index, {
            val: "",
            focus: false,
            Disable: true,
          });
        }
      });
      this.errTips = false;
      this.imgCodePuptype = false;
    },

    //获取验证码
    getVerificationCode() {
      setTimeout(() => {
        getPhoneVCode({
          phone: this.name,
          pictureVerifyCode: this.imgCode.toUpperCase(),
        }).then((res) => {
          if (res.code == 0) {
            this.imgCodePuptype = false;
            this.btnType = false;
            this.imgCodeList.forEach((item, index) => {
              if (index == 0) {
                this.$set(this.imgCodeList, index, {
                  val: "",
                  focus: false,
                  Disable: false,
                });
              } else {
                this.$set(this.imgCodeList, index, {
                  val: "",
                  focus: false,
                  Disable: true,
                });
              }
            });

            this.$messagePup("验证码已发送", "success");
            this.t = setInterval(() => {
              this.btnTime--;
              if (this.btnTime == 0) {
                this.btnType = true;
                this.btnTime = 59;
                clearInterval(this.t);
              }
            }, 1000);
          } else {
            this.$messagePup(res.msg);
            this.getImgcode();
            this.errTips = true;
            this.imgCodeList.forEach((item, index) => {
              let data = {};
              if (index == 0) {
                data = {
                  val: "",
                  focus: false,
                  Disable: false,
                };
              } else {
                data = {
                  val: "",
                  focus: false,
                  Disable: true,
                };
              }
              this.$set(this.imgCodeList, index, data);
            });
            this.$refs.ImgcodeinputS[0].focus();
          }
        });
      }, 1);
    },

    //聚焦时隐藏提示文案
    setplaceholderVal(index) {
      if (index < 3) {
        this.$set(this.errTtype, index, { type: true });
        this.$set(this.focusInputplaceholderArr, index - 1, "");
      }
    },

    //切换页面状态
    setpageType() {
      clearInterval(this.t);
      this.name = "";
      this.password = "";
      this.btnType = true;
      this.btnTime = 59;
      if (this.pageType) {
      } else {
        this.InvitationCodeNumList.forEach((item, index) => {
          if (index == 0) {
            this.$set(this.InvitationCodeNumList, index, {
              val: "",
              focus: false,
              Disable: false,
            });
          } else {
            this.$set(this.InvitationCodeNumList, index, {
              val: "",
              focus: false,
              Disable: true,
            });
          }
        });
      }
      this.imgCodeList.forEach((item, index) => {
        if (index == 0) {
          this.$set(this.imgCodeList, index, {
            val: "",
            focus: false,
            Disable: false,
          });
        } else {
          this.$set(this.imgCodeList, index, {
            val: "",
            focus: false,
            Disable: true,
          });
        }
      });
      this.getImgcode();
      this.pageType = !this.pageType;
    },

    //验证码跳转下一个输入框
    setFoucsIndex(index, type) {
      var arr = type == 1 ? this.imgCodeList : this.InvitationCodeNumList;
      let str;
      if (type == 1) {
        str = arr[index].val.replace(/[^a-zA-Z0-9]/g, "");
      } else {
        str = arr[index].val.replace(/[^\d]/g, "");
      }
      if (str != "") {
        if (index + 1 != (type == 1 ? 5 : 6)) {
          this.$set(arr[index + 1], "Disable", false);
          if (type == 1) {
            setTimeout(() => {
              this.$refs.ImgcodeinputS[index + 1].focus();
            }, 1);
          } else {
            setTimeout(() => {
              this.$refs.inputS[index + 1].focus();
            }, 1);
          }
        } else {
          if (type == 1) {
            this.$refs.ImgcodeinputS[index].blur();
          } else {
            setTimeout(() => {
              this.$refs.inputS[index].blur();
            }, 1);
          }
        }
      } else {
        this.$set(arr[index], "val", "");
      }

      if (type == 1) {
        this.imgCodeList = arr;
      } else {
        this.InvitationCodeNumList = arr;
      }
    },

    //失焦时隐藏提示文案
    DefocusplaceholderVal(index) {
      switch (index) {
        case 1:
          this.$set(
            this.focusInputplaceholderArr,
            index - 1,
            this.$t("login.txt6")
          );
          break;
        case 2:
          this.$set(
            this.focusInputplaceholderArr,
            index - 1,
            this.$t("login.txt7")
          );
          break;
      }
    },

    //去除错误提示
    clearErr() {
      this.errTtype.forEach((item, index) => {
        this.$set(this.errTtype, index, { type: true });
      });
    },

    //登录
    todesign() {
      let inputType = 0;
      if (this.name == "") {
        this.$set(this.errTtype, 0, { type: false });
        inputType = 1;
      }

      if (this.password == "") {
        this.$set(this.errTtype, 1, { type: false });
        inputType = 1;
      }

      var str = "";

      if (!this.pageType) {
        this.InvitationCodeNumList.forEach((item, index) => {
          str += item.val;
        });
        if (str == "") {
          this.$set(this.errTtype, 2, { type: false });
          inputType = 1;
        }
      }

      if (inputType == 1) {
        return;
      }

      if (!this.userAgreementType) {
        this.$messagePup(this.$t("login.txt11"));
        return;
      }

      const { name, password } = this;
      if (this.pageType) {
        this.login();
      } else {
        userRegister({
          name: name,
          password: password,
        }).then((res) => {
          if (res.code == 0) {
            this.$messagePup("注册成功", "success");
            // this.setpageType();
            this.login();
          } else if (res.code == -2) {
            this.setContent = this.$t("left.txt10");
            this.setConfirmContent = this.$t("left.txt10");
          } else {
            this.$messagePup(res.msg);
          }
        });
      }
    },

    login() {
      const { name, password } = this;
      userLogin({ login_account: name, password: password }).then((res) => {
        if (res.code === 0) {
          eventBus.$emit("my-event", "aa");
          localStorage.setItem("token", res.data.user.token);
          localStorage.setItem(
            "machineList",
            JSON.stringify(res.data.user.machineTypeList)
          );
          localStorage.setItem(
            "machineType",
            JSON.stringify(res.data.user.machineTypeList[8])
          );
          localStorage.setItem(
            "frequency",
            JSON.stringify(res.data.user.machineTypeList)
          );

          localStorage.setItem(
            "languageSet",
            this.checkIndex == 0 ? "zh" : "en"
          );
          this.$i18n.locale = this.checkIndex == 0 ? "zh" : "en";
          this.$store.commit(
            "index/setlanguageType",
            this.checkIndex == 0 ? "zh" : "en"
          );
          localStorage.setItem("clearType", 1);
          localStorage.setItem("leftNavtype", 1);
          getUserInfo().then((res) => {
            if (res.code == 0) {
              let data = {
                avatar: res.data.avatar,
                name: res.data.nickName,
                // name: "admin",
              };
              this.$store.commit("index/setuserInfo", data);
            }
          });
          setTimeout(() => {
            this.$router.push({
              path: "/modelSwitching",
            });
          }, 100);
        } else if (res.code == -2) {
          this.isShowDialog = true;
          this.setContent = this.$t("left.txt14");
          this.setConfirmContent = this.$t("left.txt15");
        } else if (res.code == -3) {
          this.isShowDialog = true;
          this.setContent = this.$t("left.txt12");
          this.setConfirmContent = this.$t("left.txt13");
        } else {
          this.$messagePup(res.msg);
        }
      });
    },

    checkMobile(number) {
      var partten = /^[1][3,4,5,7,8][0-9]{9}$/;
      return partten.test(number);
    },

    getImgcode() {
      axios({
        url: process.env.VUE_APP_BASE_URL + "/pictureVerifyCode",
        method: "get",
        responseType: "blob", // 加上这个是重点
      }).then((res) => {
        let binaryData = [];
        binaryData.push(res.data);
        this.imgCodeUrl = window.URL.createObjectURL(new Blob(binaryData));
      });
    },
  },
};
</script>

<style scoped lang="less">
@import url("./loginUp.less");
</style>

<style>
</style>